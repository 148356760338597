#triba-lightbox {
    min-height: 800px;
    max-width: 1024px;
    height: 100%;
    width: 100%;
    border-radius: 8px;
    background: #fff;
    z-index: 101;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    display: block;
    margin: auto;
    padding: 0;
    position: absolute;
    will-change: transform;
    transform-origin: top left;
    backface-visibility: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.triba-close-button {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid #01192b;
    position: absolute;
    top: 6px;
    right: 6px;
    cursor: pointer;
    animation: .4s expand ease-in;
    z-index: 101;
}

.triba-close-button:before,
.triba-close-button:after {
    content: "";
    width: 75%;
    height: 3px;
    background-color: #01192b;
    position: absolute;
}

.triba-close-button:before {
    transform: rotate(45deg);
}

.triba-close-button:after {
    transform: rotate(-45deg);
}

.triba-close-button:hover {
    background: #01192b;
}

.triba-close-button:hover::before,
.triba-close-button:hover::after {
    background-color: #fff;
}