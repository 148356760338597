#triba-loading {
    display: flex;
    justify-content: center;
    align-items: center;
}

#triba-loading svg {
    position: relative;
    top: unset;
}

#outline {
    stroke-dasharray: 2.42777px, 242.77666px;
    stroke-dashoffset: 0;
    animation: anim 1.6s linear infinite;
}

@keyframes anim {
    12.5% {
        stroke-dasharray: 33.98873px, 242.77666px;
        stroke-dashoffset: -26.70543px;
    }

    43.75% {
        stroke-dasharray: 84.97183px, 242.77666px;
        stroke-dashoffset: -84.97183px;
    }

    100% {
        stroke-dasharray: 2.42777px, 242.77666px;
        stroke-dashoffset: -240.34889px;
    }
}