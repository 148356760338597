.clock-icon {
    position: relative;
    border-radius: 50%;
    border: 2px solid;
    width: 14px;
    height: 14px;
    display: inline-block;
    margin: 0 6px 0 0;
    vertical-align: middle;
}

.clock-icon:after {
    position: absolute;
    width: 0px;
    height: 40%;
    display: block;
    border-left: 2px solid #01192b;
    ;
    content: '';
    left: calc(50% - 1px);
    bottom: 50%;
}

.clock-icon:before {
    position: absolute;
    width: 0px;
    height: 30%;
    display: block;
    border-left: 2px solid #01192b;
    content: '';
    left: calc(50% - 1px);
    top: 50%;
    transform: rotate(-45deg);
    transform-origin: top left;
}