.capacity_animate_wrapper {
    overflow: hidden;
    display: inline-block;
    position: relative;
    width: 10px;
    height: 20px;
    vertical-align: top;
    margin: 0 6px;
}

.capacity_animate {
    display: flex;
    flex-direction: column;
    position: absolute;
    height: 50px;
    justify-content: space-between;
    top: 0;
    transition: 1s ease-in-out;
}

.capacity_animate span {
    font-weight: bold;
}

.capacity_animate.animate {
    top: -30px;
}