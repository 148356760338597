#triba-booking-form {
    align-items: center;
    background: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    left: 0px;
    overflow: hidden;
    top: 0px;
    width: 100%;
}

#triba-booking-form>div {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    margin: 24px 0 0 0;
    transition: 0.3s;
    width: 100%;
    padding: 0 12px;
    box-sizing: border-box;
}

#triba-booking-form div#triba-booking-form-class-info {
    align-items: center;
    background: #fff;
    border-right: 1px solid #eee;
    color: #444;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    position: relative;
}

#triba-booking-form div#triba-booking-form-class-info img {
    background: #f3f3f3;
    flex-grow: 1;
    height: 70%;
    margin: 0 0 24px;
    object-fit: cover;
    width: 100%;
    z-index: 99;
    opacity: 0;
    transition: opacity 0.3s ease-in;
    transition-delay: 0.3s;
    position: relative;
}

#triba-booking-form div#triba-booking-form-class-info img.loaded {
    opacity: 1;
}

/* Update loader styles */
.image-loader {
    width: 40px;
    height: 40px;
    border: 3px solid #f3f3f3;
    border-top: 3px solid #275dbb;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@keyframes spin {
    0% { transform: translate(-50%, -50%) rotate(0deg); }
    100% { transform: translate(-50%, -50%) rotate(360deg); }
}

#triba-booking-form div#triba-booking-form-class-info svg {
    display: inline-block;
    height: 15px;
    margin: 0 6px 0 0;
    width: 15px;
    position: relative;
    top: unset;
}


#triba-booking-form div section {
    /* animation: .4s posit ease-in; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    width: 100%;
    margin: 0;
    padding: 0 12px;
    box-sizing: border-box;
}

.triba-calendar form {
    display: block;
    margin: 0;
    padding: 12px;
    box-sizing: border-box;
    width: 100%;
}

.triba-calendar form#check-email {
    /* animation: .4s expand ease-in; */
}

#triba-calendar .triba-calendar form button {
    background: #275dbb;
    border-radius: 50px;
    border: none;
    color: #fff;
    font-weight: bold;
    height: 35px;
    outline: none;
    width: 180px;
}


@keyframes expand {

    from {
        opacity: 0;
        transform: scale(0);
    }
}

@keyframes posit {

    from {
        bottom: 0;
        transform: scale(0);
    }
}

.triba-card-errors {
    color: #fa755a;
    font-weight: bold;
    margin: 0 0 12px;
    padding: 6px;
    max-width: 400px;
}


.StripeElement {
    -webkit-transition: box-shadow 150ms ease;
    background-color: white;
    border-radius: 4px;
    border: 1px solid transparent;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    color: #32325d;
    height: 40px;
    padding: 10px 12px;
    transition: box-shadow 150ms ease;
}

input:focus,
.StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
    border-color: #fa755a;
}

.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}

.card-errors {
    margin: 12px;
}

/**
 * The CSS shown here will not be introduced in the Quickstart guide, but shows
 * how you can use CSS to style your Element's container.
 */
.StripeElement {
    -webkit-transition: box-shadow 150ms ease;
    background-color: white;
    border-radius: 4px;
    border: 1px solid transparent;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    box-sizing: border-box;
    height: 40px;
    margin: 0 0 18px;
    max-width: 400px;
    min-width: 320px;
    padding: 10px 12px;
    transition: box-shadow 150ms ease;
}

.StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
    border-color: #fa755a;
}

.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}

.circle {
    stroke-dasharray: 119.4;
    stroke-dashoffset: 119.4;
}

.circle {
    animation-fill-mode: forwards;
    animation: circle 1s ease-in-out forwards;
}

.tick {
    stroke-dasharray: 35;
    stroke-dashoffset: 35;
}

svg .tick {
    animation-delay: 0.95s;
    animation-fill-mode: forwards;
    animation: tick 0.8s ease-out forwards;
}

@keyframes circle {

    from {
        stroke-dashoffset: 119.4;
    }

    to {
        stroke-dashoffset: 238.8;
    }
}

@keyframes tick {

    from {
        stroke-dashoffset: 35;
    }

    to {
        stroke-dashoffset: 0;
    }
}

@keyframes title {

    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

#triba-paymentform p span {
    background: #eef2f5;
    border-radius: 4px;
    color: #01192b;
    display: block;
    font-size: 0.9em;
    font-weight: bold;
    margin: 0;
    padding: 6px;
    text-align: center;
}

#triba-success p span {
    background: #eef2f5;
    border-radius: 4px;
    color: #01192b;
    display: block;
    font-size: 0.9em;
    font-weight: bold;
    margin: 6px 0 0;
    padding: 6px;
    text-align: center;
}

#triba-paymentform p span.transparent {
    background: none;
    padding: 0;
}

#triba-paymentform {
    display: flex;
    flex-direction: column;
    margin: 0 0 24px;
}

.triba-membership-paymentform {
    animation: .4s expand ease-in;
}

#triba-paymentform button.triba-tertiary {
    align-self: end;
    margin: 0 0 24px;
}

#triba-success svg {
    margin: 0 0 12px;
}

#triba-success p {
    text-align: center;
}

.triba-payment-message {
    color: #32325d;
    margin: 0 0 12px;
}

#triba-paymentform svg {
    fill: #b0c8f1;
    height: 50px;
}

#triba-paymentform button {
    align-self: center;
}

.formbuttons {
    align-items: center;
    display: flex;
    justify-content: space-evenly;
}

#waiver-content {
    border-radius: 4px;
    border: 1px solid #aabbc8;
    height: 190px;
    margin: 0 0 24px;
    max-width: 450px;
    overflow: scroll;
    padding: 12px;
}


@container (width > 690px) {

    #triba-booking-form {
        flex-direction: row;
    }

    #triba-booking-form div section {
        height: 30%;
    }

    #triba-booking-form div#triba-booking-form-class-info {
        width: 40%;
    }

    #triba-booking-form>div {
        justify-content: center;
        margin: 0;
        width: 60%;
    }

    #triba-booking-form.success>div {
        width: 40%;
    }

    #triba-booking-form.success div#triba-booking-form-class-info {
        width: 60%;
    }

    #triba-booking-form div#triba-booking-form-class-info img {
        margin: 0px;
    }
}