#triba-calendar {
  container-type: size;
  position: relative;
}

body {
  font-size: 16px;
}

#triba-calendar h2 {
  color: #01192b;
  font-size: 24px;
  font-weight: bold;
  margin: 0 0 18px;
}

#triba-calendar h3 {
  color: #01192b;
  margin: 0 0 18px;
}

#triba-calendar p {
  color: #01192b;
  margin: 0 0 12px;
  text-align: left;
}

#triba-calendar .inputWrapper {
  display: flex;
  flex-direction: column;
  border: none;
  align-items: flex-start;
}

#triba-calendar label {
  color: #01192b;
  margin: 0 0 6px;
  text-align: left;
}

#triba-calendar input {
  border-radius: 4px;
  border: 1px solid #c6cad1;
  height: 45px;
  margin: 0 0 12px;
  padding: 0 0 0 12px;
  min-width: 100%;
  max-width: 280px;
  box-sizing: border-box;
}

#triba-calendar input:focus {
  border: 1px solid #5c87d1;
  outline: 1px solid #5c87d1;
}

#triba-calendar button {
  color: #01192b;
  cursor: pointer;
}

#triba-calendar button.triba-tertiary,
.triba-calendar form button.triba-tertiary {
  background: none;
  border: none;
  box-shadow: none;
  color: #01192b;
  font-weight: bold;
  height: auto;
  outline: none;
  text-decoration: underline;
  width: auto;
}

#triba-calendar button.triba-tertiary:hover {
  box-shadow: none;
  transform: scale(1.1);
}

#triba-calendar {
  margin: 12px 0;
}

.triba-calendar {
  margin: 0 18px;
  text-align: center;
  position: relative;
}

#triba-calendar-wrapper {
  margin: 24px auto;
  max-width: 1024px;
}

.triba-calendar-header {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1024px;
  height: auto;
  background: transparent;
}

.triba-calendar ul {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 6px 0 rgb(0 0 0 / 10%);
  display: flex;
  flex-wrap: wrap;
  min-height: 800px;
  padding: 0;
  width: 100%;
}

.triba-calendar ul li {
  border-right: 1px solid #eee;
  box-sizing: border-box;
  display: block;
  height: auto;
  list-style: none;
  padding: 0 0 6px;
  position: relative;
  width: 100%;
}

.calendardate {
  background: #f3f8ff;
  color: #445870;
  display: block;
  font-size: 14px;
  font-weight: bold;
  margin: 0 0 18px;
  overflow-wrap: break-word;
  padding: 4px 0;
  text-align: center;
  transition: 0.3s;
}

.studio .calendardate {
  background-color: #015e4a1c;
  color: #015e4a;
}

@container (width > 690px) {

  .triba-calendar ul li {
    width: 14.2857142857%;
  }

  .triba-calendar-header {
    flex-direction: row;
  }
}

.calendarClass {
  background-color: #ddecff;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
  box-sizing: border-box;
  color: #41587b;
  cursor: pointer;
  height: auto;
  margin: 0 10px 12px;
  padding: 6px 10px;
  position: relative;
  text-align: left;
  transition: 0.3s;
  transition: 0.3s;
  width: calc(100% - 20px);
}

.calendarClass:hover {
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 8%);
  transform: scale(1.03);
  z-index: 99;
}

.calendarClass.disabled, .calendarClass.studio.disabled, .calendarClass.stream.disabled {
  background-color: #4e4e4e1c;
  color: #444;
}

.calendarClass.disabled:hover, .calendarClass.studio.disabled:hover, .calendarClass.stream.disabled:hover {
  transform:unset;
  cursor: auto;
}

.calendarClass span {
  display: block;
  font-weight: bold;
  margin: 0 0 6px;
}

.calendarClass span.calendarClass--time {
  font-weight: normal;
}

.calendarClass.cancel {
  background: #ddd;
}

.calendarClass img {
  height: 1px;
  position: absolute;
  visibility: hidden;
  width: 1px;
}

.calendarClass.studio {
  background-color: #015e4a1c;
  color: #015e4a;
}

.triba-calendar button.calendartoggle {
  align-items: center;
  background: #fff;
  border-radius: 3px;
  border: 1px solid #eee;
  box-shadow: 0 4px 6px 0 rgb(0 0 0 / 10%);
  cursor: pointer;
  display: inline-flex;
  height: 35px;
  justify-content: center;
  padding: 0;
  transition: 0.2s cubic-bezier(.16, -1.33, .69, 1.86);
  transition: 0.4s;
  vertical-align: middle;
  width: 35px;
}

.triba-calendar button.calendartoggle:hover {
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 8%);
  transform: scale(1.1);
  z-index: 99;
}

.triba-calendar-dateselector {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 24px 0 12px;
}

.triba-calendar-dateselector input {
  margin: 0 12px;
}

#triba-calendar .triba-calendar-dateselector p {
  display: inline-block;
  font-weight: bold;
  margin: 0 12px;
  text-align: center;
  flex-grow: 1;
}

.triba-calendar button.calendartoggle svg {
  height: 15px;
}

span.loadingButton {
  background: #275dbb;
  border-radius: 50px;
  border: none;
  color: #fff;
  display: inline-block;
  height: 35px;
  outline: none;
  width: 180px;
}

span.loadingButton svg {
  height: 35px;
}

#class_type_toggle {
  align-items: center;
  display: flex;
}

#class_type_toggle span {
  font-weight: normal;
  transition: 0.3s;
  width: 54px;
}

#class_type_toggle span:first-of-type {
  width: 48px;
}

#class_type_toggle span.selected {
  font-weight: bold;
}

input[type=checkbox]#calendar_switch {
  height: 0;
  visibility: hidden;
  width: 0;
  min-width: 0;
}

label#calendar_switch_label {
  background: #01192b;
  border-radius: 100px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: inline-block;
  height: 30px;
  margin: 0 12px;
  position: relative;
  text-indent: -9999px;
  transition: 0.3s;
  width: 60px;
}

label#calendar_switch_label:after {
  background: #fff;
  border-radius: 90px;
  bottom: 0;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1);
  content: '';
  height: 24px;
  left: 3px;
  margin: auto;
  position: absolute;
  top: 0;
  transition: 0.3s;
  width: 24px;
}

input#calendar_switch:checked+label#calendar_switch_label {
  background: #848a94;
}

input#calendar_switch:checked+label#calendar_switch_label:after {
  left: calc(100% - 3px);
  transform: translateX(-100%);
}

label#calendar_switch_label:active:after {
  width: 50%;
}

select {
  border-radius: 3px;
  border: 1px solid #ccc;
  height: 35px;
  margin: 0 0 12px;
}

#pass-icon-wrapper {
  align-items: center;
  display: flex;
}


#pass-icon-wrapper svg {
  height: 50px;
}

#pass-icon-wrapper select {
  margin: 0 0 0 12px;
  width: 180px;
}

#pass-icon-wrapper p {
  font-weight: bold;
  margin: 0 0 0 12px;
}